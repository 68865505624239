import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPaperPlane } from '@fortawesome/pro-light-svg-icons'
import axios from 'axios'
import { ReCaptcha, loadReCaptcha } from 'react-recaptcha-v3'
import { faCheck, faSpinner } from '@fortawesome/pro-solid-svg-icons'
import smoothScroll from 'scroll-to-element'

class ContactForm extends React.Component {
  phoneNumberPrefixInputRef = null
  fullPhoneNumberInputRef = null

  STATE_READY = 'Envoyer un message'
  STATE_SENDING = 'Envoi en cours...'
  STATE_SENT = 'Message envoyé'
  STATE_ERROR = 'Réessayer'

  defaultState = {
    phoneInputFocused: false,
    isDummyPhoneInputVisible: true,
    isPhoneInputVisible: false,
    current: this.STATE_READY,
    interestedIn: '',
    numberPrefix: '',
    message: '',
    number: '',
    email: '',
    nda: false,
    reCaptchaResponseToken: '',
    reloadRecaptcha: true,
    isReCaptchaScriptLoaded: false,
    errorMessage: null
  }

  constructor(props) {
    super(props)
    this.state = this.defaultState;
  }

  componentDidMount() {
    loadReCaptcha(process.env.GATSBY_RECAPTCHA_SITE_KEY_V3);
    document.addEventListener('click', this.onGlobalClick);
  }

  componentWillUnmount() {
    document.removeEventListener('click', this.onGlobalClick)
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (
      this.state.numberPrefix.length !== 0 ||
      this.state.number.length !== 0
    ) {
      this.fullPhoneNumberInputRef.value = `${this.state.numberPrefix} ${
        this.state.number
      }`
    } else {
      this.fullPhoneNumberInputRef.value = null
      this.fullPhoneNumberInputRef.placeholder = 'Numéro de téléphone'
    }
    this.state.phoneInputFocused && this.phoneNumberPrefixInputRef.focus()
  }

  verifyCallback = recaptchaToken => {
    this.setState({ reCaptchaResponseToken: recaptchaToken })
  }

  onGlobalClick = e => {
    const id = e.target.id
    if (id !== 'contact-phone-prefix' && id !== 'contact-phone-number') {
      this.setState({
        isDummyPhoneInputVisible: true,
        isPhoneInputVisible: false,
      })
    }
    if(id === "contact-phone-merged-input"){
      this.setState({
        isDummyPhoneInputVisible: false,
        isPhoneInputVisible: true,
        phoneInputFocused: true,
      })
    }
  }

  onPhoneInputChange = e => {
    const { id, value } = e.target

    switch (id) {
      case 'contact-phone-prefix':
        this.setState({ numberPrefix: value })
        break
      case 'contact-phone-number':
        this.setState({ number: value, phoneInputFocused: false })
        break
      default:
        break
    }
  }

  handleSubmit = event => {
    event.preventDefault();
    if (this.state.current === this.STATE_SENDING) return;

    this.setState({
      current: this.STATE_SENDING,
      reloadRecaptcha: false,
    });
    window.grecaptcha.execute(process.env.GATSBY_RECAPTCHA_SITE_KEY_V3).then(token => {
      const model = {
        'g-recaptcha-response': token,
        message: this.state.message,
        email: this.state.email,
        'phone-prefix': this.state.numberPrefix,
        'phone-number': this.state.number,
        nda: this.state.nda,
      };
      axios.post(this.props.mailingUrl, model)
        .then(() => {
          this.setState({
            email: '',
            number: '',
            numberPrefix: '',
            nda: false,
            current: this.STATE_SENT,
            reloadRecaptcha: true,
          })
          this.formRef.reset()
        })
        .catch(err => {
          let errorMessage = 'We couldn\'t send the message. Please try again.';

          if (err && err.response && err.response.data && err.response.data.meta && err.response.data.meta.errors && err.response.data.meta.errors.length) {
            errorMessage = err.response.data.meta.errors[0].reason;
          }

          this.setState({
            current: this.STATE_ERROR,
            errorMessage
          });
        });

    }, () => {
      this.setState({
        current: this.STATE_ERROR,
        errorMessage: 'Recaptcha error. Please try again.'
      })
    })
  };

  render() {
    const { onCloseFormClick, isOpen, isStatic, home } = this.props

    return (
      <div
        className={`${
          isStatic ? '' : isOpen ? 'slideUp' : 'slideDown'
        } ${home ? 'contact-layer-form-home' : 'contact-layer-form'} d-none d-md-block`}
      >
        <div className="container">
          <div className="row">
            <div className="contact-form-area col-md-12 offset-md-0 col-lg-9 mx-lg-auto col-xl-8">
              <div className="card card-block contact-form-card">
                <div className="row">
                  <div className="col-md-10 offset-md-1">
                    <form
                      id="contactform-bottom"
                      ref={r => (this.formRef = r)}
                      className="contact-form form-horizontal"
                      action="#"
                      onSubmit={this.handleSubmit}
                    >
                      <fieldset>
                        <p className="lead text-center text-muted">
                          <span className="text-primary"> Une solide équipe de développeurs est prête à commencer à travailler sur l'application. Laissez-nous un message et nous vous répondrons dans les 6 heures ouvrables (GMT +1 Lu-Ve de 9h-17h) </span>
                        </p>

                        <div className="form-group">
                          <textarea
                            onChange={e =>
                              this.setState({
                                message: e.target.value,
                                phoneInputFocused: false,
                              })
                            }
                            className="form-control form-control-lg"
                            rows="4"
                            name="message"
                            id="contact-message"
                            placeholder="Message"
                            tabIndex="2"
                            required
                            style={{ resize: 'none' }}
                          />
                        </div>
                        <div className="form-group form-check mx-2">
                          <input
                            type="checkbox"
                            className="form-check-input"
                            id="contact-nda"
                            name="nda"
                            tabIndex="3"
                            onChange={e =>
                              this.setState(prevProps => ({
                                nda: !prevProps.nda,
                              }))
                            }
                          />
                          <label
                            className="form-check-label text-muted"
                            htmlFor="contact-nda"
                          >
                            Je voudrais recevoir un Accord de confidentialité
                          </label>
                        </div>
                        <div className="collapse" id="collapse-contact-form" />
                        <div className="form-group">
                          <input
                            onChange={e =>
                              this.setState({
                                email: e.target.value,
                                phoneInputFocused: false,
                              })
                            }
                            type="email"
                            name="email"
                            id="contact-email"
                            className="form-control form-control-lg"
                            placeholder="E-mail"
                            tabIndex="4"
                            required
                            value={this.state.email}
                          />
                        </div>

                        <div
                          id="contact-phone-merged"
                          style={
                            this.state.isDummyPhoneInputVisible
                              ? { display: 'block' }
                              : { display: 'none' }
                          }
                        >
                          <div className="form-group">
                            <input
                              id="contact-phone-merged-input"
                              ref={r => (this.fullPhoneNumberInputRef = r)}
                              type="text"
                              autoComplete="off"
                              className="form-control form-control-lg"
                              placeholder="Numéro de téléphone"
                              tabIndex="5"
                            />
                          </div>
                        </div>

                        <div
                          id="contact-phone"
                          style={
                            this.state.isPhoneInputVisible
                              ? { display: 'block' }
                              : { display: 'none' }
                          }
                        >
                          <div className="row">
                            <div className="col-3">
                              <div className="form-group">
                                <input
                                  id="contact-phone-prefix"
                                  ref={r =>
                                    (this.phoneNumberPrefixInputRef = r)
                                  }
                                  onChange={this.onPhoneInputChange}
                                  type="number"
                                  autoComplete="off"
                                  name="phone-prefix"
                                  className="form-control form-control-lg"
                                  placeholder="Préfixe"
                                  tabIndex="6"
                                />
                              </div>
                            </div>
                            <div className="col-9">
                              <div className="form-group">
                                <input
                                  id="contact-phone-number"
                                  onChange={this.onPhoneInputChange}
                                  type="number"
                                  name="phone-number"
                                  autoComplete="off"
                                  className="form-control form-control-lg"
                                  placeholder="Numéro de téléphone "
                                  tabIndex="7"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                        {this.state.current === this.STATE_ERROR && (
                          <div className="row">
                            <div className="col-md-12">
                              <div className="text-danger">
                                {this.state.errorMessage}
                              </div>
                            </div>
                          </div>
                        )}
                        <div className="row">
                          <div className="col-md-6">
                            <div className="form-group form-check mt-2">
                              <input
                                type="checkbox"
                                className="form-check-input"
                                id="contact-form-gdpr-consent"
                                name="gdpr_consent"
                                required
                              />
                              <label
                                className="form-check-label small text-muted"
                                htmlFor="contact-form-gdpr-consent"
                              >
                                Je consens à recevoir des messages de la société Zaven Sp. z o.o. Le consentement est facultatif et je peux le retirer à tout moment pour cesser d’utiliser mes données à caractère personnel.
                              </label>
                            </div>
                          </div>
                          <div className="col-md-6 text-right">
                            <button
                              type="submit"
                              name="send_message_button"
                              className="btn btn-lg btn-outline-primary"
                              tabIndex="8"
                              id={this.state.current === this.STATE_SENT ? "message-sent-btn" : "send-message-btn"}
                            >
                              {this.state.current === this.STATE_SENDING && (
                                <FontAwesomeIcon
                                  icon={faSpinner}
                                  spin
                                  style={{ marginRight: '0.5rem' }}
                                />
                              )}
                              {this.state.current === this.STATE_READY && (
                                <FontAwesomeIcon
                                  icon={faPaperPlane}
                                  style={{ marginRight: '0.5rem' }}
                                />
                              )}
                              {this.state.current === this.STATE_SENT && (
                                <FontAwesomeIcon
                                  style={{ marginRight: '0.5rem' }}
                                  icon={faCheck}
                                />
                              )}
                              {this.state.current}
                            </button>
                            <div
                              className="form-group"
                              style={{ margin: '13px 0 0 0' }}
                            >
                              <small style={{ display: 'inline-block' }}>
                              Ce site Internet est sécurisé pat reCAPTCHA, 
                               
                                <a href="https://policies.google.com/privacy">
                                 la Politique de confidentialité  
                                </a>{' '}
                                Google{' '}
                                et{' '}
                                <a href="https://policies.google.com/terms">
                                les conditions relatives à l’utilisation des services Google
                                </a>{' '}
                                sont applicables.
                              </small>
                            </div>
                            {!isStatic && (
                              <p style={{ marginTop: '20px' }}>
                                <small>
                                 Vous n’aimez pas les formulaires?{' '}
                                  <button
                                    className="button-like-link"
                                    onClick={onCloseFormClick}
                                  >
                                    Afficher les coordonnées.
                                  </button>
                                </small>
                              </p>
                            )}
                          </div>
                        </div>
                      </fieldset>
                    </form>
                  </div>
                </div>
                <div className={`clearfix ${isStatic ? '' : 'fake-height'}`} />
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default ContactForm

export const Category = {
  MobileApps: 'mobileapps',
  WebApps: 'webapps',
  Outsourcing: 'outsourcing',
  FreeConsultation: 'free-consultation',
  Other: 'other',
}

export const scrollToForm = (category, offsetY, duration) => {
  smoothScroll('#contact-partial', {
    offset: offsetY || 0,
    duration: duration || 1000,
  })

  if (category) {
    document.getElementById('contact-category').value = category
    document.getElementById('contact-message').focus()
  }
}
